import React, {
  useState,
  useEffect,
  createContext,
  useContext,
  useCallback,
} from "react";
import SHA1 from "crypto-js/sha1";
import MD5 from "crypto-js/md5";
import notify from "devextreme/ui/notify";
import userImg from "../themes/dtz/images/user.webp";
import config from "../config/config.json";

const AuthContext = createContext({});
const useAuth = () => useContext(AuthContext);
const defaultUser = {
  email: "",
  avatarUrl: userImg,
};

function AuthProvider(props) {
  const [user, setUser] = useState(false);
  const [loading, setLoading] = useState(true);

  const logIn = useCallback(async (email, password) => {
    let pw = MD5(password);
    pw = SHA1(email + pw);

    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body:
        '{"login_request":{"login":"' + email + '","passwort":"' + pw + '"}}',
    };
    setLoading(true);
    fetch(
      config.buildtype === "release"
        ? config.destination_release.login
        : config.destination_debug.login,
      requestOptions
    )
      .then(async (response) => {
        const data = await response.json();
        if (!response.ok) {
          const error = (data && data.message) || response.status;
          return Promise.reject(error);
        }
        if (data.login_response.message === "success") {
          sessionStorage.setItem("key", data.login_response.key);
          sessionStorage.setItem("name", data.login_response.name);
          sessionStorage.setItem("key", data.login_response.key);
          setUser({
            email: data.login_response.name,
            avatarUrl: defaultUser.avatarUrl,
            key: data.login_response.key
          });
          notify("Login erfolgreich!", "success", 2000);
          setLoading(false);
        } else {
          notify("Benutzername oder Passwort falsch!", "error", 2000);
          setLoading(false);
        }
      })
      .catch((error) => {
        setLoading(false);
        notify("Login fehlgeschlagen! ", "error", 5000);
        console.error("Login fehlgeschlagen! ", error);
      });
  }, []);

  const logOut = useCallback(() => {
    // Clear user data
    sessionStorage.clear();
    window.location.reload();
    setUser();
  }, []);

  useEffect(() => {
    //setUser(defaultUser);
    setLoading(false);
  }, []);

  return (
    <AuthContext.Provider value={{ user, logIn, logOut, loading }} {...props} />
  );
}

export { AuthProvider, useAuth };
