import React, { useState } from "react";
import { Button } from "devextreme-react/button";
import DateBox from "devextreme-react/date-box";

import "../../themes/dtz/css/dtzZeitraum.css";
import moment from "moment";

export default function DTZZeitraum(props) {
  const [from, setFrom] = useState(moment().subtract(7, "days"));
  const [to, setTo] = useState(moment());

  function onUebernehmen() {
    props.datum([
      moment(from).format("YYYY-MM-DDTHH:mm:ssZ"),
      moment(to).format("YYYY-MM-DDTHH:mm:ssZ"),
    ]);
  }

  function onValueChangedFrom(e) {
    setFrom(e.value);
  }

  function onValueChangedTo(e) {
    setTo(e.value);
  }

  return (
    <div className="dtzZeitraum">
      <div className="dx-fieldset">
        <div className="dx-field-label">
          Von
          <div className="dx-field-value">
            <DateBox
              pickerType={"calendar"}
              className="dtzZeitraumVon"
              defaultValue={from}
              onValueChanged={onValueChangedFrom}
              type="datetime"
            />
          </div>
        </div>
        <div className="dx-field-label">
          Bis
          <div className="dx-field-value">
            <DateBox
              pickerType={"calendar"}
              className="dtzZeitraumBis"
              defaultValue={to}
              onValueChanged={onValueChangedTo}
              type="datetime"
            />
          </div>
        </div>
        <Button
          className="dtzZeitraumButton"
          text="Übernehmen"
          stylingMode="contained"
          onClick={onUebernehmen}
        />
      </div>
    </div>
  );
}
