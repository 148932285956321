import React, { useEffect, useState } from "react";
import { Slider } from "devextreme-react/slider";
import { Switch } from "devextreme-react/switch";
import { Button } from "devextreme-react/button";
import notify from "devextreme/ui/notify";
import "../../themes/dtz/css/dtzSettings.css";

/*
function processConfigData(ds) {
  let backgroundShow = undefined;
  let backgroundOpacity = undefined;

  ds.forEach((element) => {
    switch (element["name"]) {
      case "D.AV.Bg":
        switch (element["wert"]) {
          case "0":
            backgroundShow = 0;
            break;
          case "1":
            backgroundShow = 1;
            break;
          default:
            break;
        }
        break;
      case "D.AV.Opacity":
        backgroundOpacity = parseFloat(element["wert"]);
        break;
      default:
        break;
    }
    localStorage.setItem("backgroundShow", backgroundShow);
    localStorage.setItem("backgroundOpacity", backgroundOpacity);
  });
}*/
/*
function setConfig(backgroundShow, backgroundOpacity){
    const requestOptions = {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            session: sessionStorage.getItem("key"),
        },
        body: '{ "setusersettings_request": { "usersettings": [{ "name": "D.AV.Bg", "wert": "' + backgroundShow + '" },{ "name": "D.AV.Opacity", "wert": "' + backgroundOpacity + '" }] } }',
        };
        fetch(
        config.buildtype === "release" ? config.destination_release.setconfig : config.destination_debug.setconfig,
        requestOptions
        )
        .then(async (response) => {
            const data = await response.json();
            if (!response.ok) {
            const error = (data && data.message) || response.status;
            return Promise.reject(error);
            }
            if(data.setusersettings_response.resultcode != 1001)
                notify("Einstellungen wurden erfolgreich gespeichert!", "success", 5000);
            else
                notify("Einstellungen konnten nicht gespeichert werden!", "error", 5000);  
            return data;
        })
        .catch((error) => {
            notify("Einstellungen konnten nicht gespeichert werden!", "error", 5000);
            console.error("Einstellungen konnten nicht gespeichert werden!", error);
        });
}*/
/*
function getConfig() {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      session: sessionStorage.getItem("key"),
    },
    body: '{"getusersettings_request":""}',
  };
  fetch(
    config.buildtype === "release" ? config.destination_release.getconfig : config.destination_debug.getconfig,
    requestOptions
  )
    .then(async (response) => {
      const data = await response.json();
      if (!response.ok) {
        const error = (data && data.message) || response.status;
        return Promise.reject(error);
      }
      
      let ds = data.getusersettings_response.usersettings;
      processConfigData(ds);
    })
    .catch((error) => {
      notify("Einstellungen konnten nicht geladen werden!", "error", 5000);
      console.error("Einstellungen konnten nicht geladen werden!", error);
    });
}*/

export default function DTZSettings(props) {
  const [backgroundShow, setBackgroundShow] = useState({
    value: localStorage.getItem("backgroundShow")
      ? !!+localStorage.getItem("backgroundShow")
      : 1,
  });
  const [backgroundOpacity, setBackgroundOpacity] = useState({
    value: localStorage.getItem("backgroundOpacity")
      ? localStorage.getItem("backgroundOpacity")
      : 25,
  });
  const backgroundShowRef = React.useRef();
  const backgroundOpacityRef = React.useRef();

  useEffect(() => {
    // getConfig();

    if (backgroundShowRef.current) {
      setBackgroundShow({
        value: localStorage.getItem("backgroundShow")
          ? !!+localStorage.getItem("backgroundShow")
          : 1,
      });
    }
    if (backgroundOpacityRef.current) {
      setBackgroundOpacity({
        value: localStorage.getItem("backgroundOpacity")
          ? localStorage.getItem("backgroundOpacity")
          : 25,
      });
    }
  }, []);

  function onSave() {
    //setConfig(localStorage.getItem("backgroundShow"), localStorage.getItem("backgroundOpacity"))
    localStorage.setItem("backgroundOpacity", backgroundOpacity.value);
    localStorage.setItem("backgroundShow", backgroundShow.value ? 1 : 0);
    notify("Einstellungen erfolgreich gespeichert!", "success", 3000);
    window.location.reload();
  }

  function handleOpacityChange(e) {
    setBackgroundOpacity({ value: e.value });
  }

  function handleShowChange(e) {
    setBackgroundShow({ value: e.value });
  }

  return (
    <div id="dtzSettings">
      <div className="dtzSettingsHintergrundAnzeigenText">
        Hintergrund anzeigen
      </div>
      <Switch
        ref={backgroundShowRef}
        className="dtzSettingsHintergrundAnzeigenSwitch"
        defaultValue={backgroundShow.value}
        onValueChanged={handleShowChange}
      />
      <div className="dtzSettingsHintergrundDeckkraftText">
        Hintergrund Deckkraft
      </div>
      <Slider
        ref={backgroundOpacityRef}
        className="dtzSettingsHintergrundDeckkraftSlider"
        min={0}
        max={100}
        defaultValue={backgroundOpacity.value}
        onValueChanged={handleOpacityChange}
        tooltip={{
          enabled: true,
          showMode: "always",
          position: "bottom",
        }}
      />
      <Button
        className="dtzSettingsHintergrundSaveButton"
        text="Übernehmen"
        stylingMode="contained"
        onClick={onSave}
      />
    </div>
  );
}
