import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { SingleCard } from "./layouts";
import { LoginForm } from "./components";
import "./themes/dtz/css/dtzNoDrag.css";

export default function LoginContentRender() {
  return (
    <div>
      <div className="loginHeader">
        <div className="loginHeaderText">DTZ Silomanagement</div>
      </div>
      <img className={"loginImage"} alt=""></img>
      <div className={"loginForm"}>
        <Switch>
          <Route exact path="/">
            <SingleCard title="Anmeldung">
              <LoginForm />
            </SingleCard>
          </Route>
          <Redirect to={"/login"} />
        </Switch>
      </div>
    </div>
  );
}
