import React from "react";
import check_gray from "../images/status/check_grey.png";
import warning_gray from "../images/status/warning_grey.png";
import cross_gray from "../images/status/cross_grey.png";
import check from "../images/status/check.png";
import warning from "../images/status/warning.png";
import cross from "../images/status/cross.png";
import "../../../pages/home/home";

function checkArt(arg) {
  if (Math.trunc(arg / 1000) * 1000 !== 100000) return "#AD1832";
  else return "#1c86ee";
}

export default function AnsichtItem(item) {
  return (
    <div className="ansichtitem">
      <div className="badgecontainer">
        <div className="ansichttext" style={{ color: checkArt(item.art) }}>
          {item.name}
        </div>
        {item.status2 <= 0 && (
          <img
            id="cross_gray"
            alt="status"
            className="ansichtbadge"
            src={cross_gray}
          />
        )}
        {item.status2 > 0 && (
          <img id="cross" alt="status" className="ansichtbadge" src={cross} />
        )}
        {item.status2 > 0 && (
          <div className="badgeredfront" data-badge={item.status2} />
        )}
        {item.status1 <= 0 && (
          <img
            id="warning_gray"
            alt="status"
            className="ansichtbadge"
            src={warning_gray}
          />
        )}
        {item.status1 > 0 && (
          <img
            id="warning"
            alt="status"
            className="ansichtbadge"
            src={warning}
          />
        )}
        {item.status1 > 0 && (
          <div className="badgeorgangefront" data-badge={item.status1} />
        )}
        {item.status0 > 0 && (
          <img id="check" alt="status" className="ansichtbadge" src={check} />
        )}
        {item.status0 > 0 && (
          <div className="badgegreen" data-badge={item.status0} />
        )}
        {item.status0 <= 0 && (
          <img
            id="check_gray"
            alt="status"
            className="ansichtbadge"
            src={check_gray}
          />
        )}
      </div>
    </div>
  );
}
