import "devextreme/dist/css/dx.common.css";
import "./themes/generated/theme.base.css";
import "./themes/generated/theme.additional.css";
import React, { useEffect, useState } from "react";
import { MemoryRouter as Router } from "react-router-dom";
import "./dx-styles.scss";
import LoadPanel from "devextreme-react/load-panel";
import { NavigationProvider } from "./contexts/navigation";
import { AuthProvider, useAuth } from "./contexts/auth";
import { useScreenSizeClass } from "./utils/media-query";
import Content from "./Content";
import LoginContent from "./LoginContent";
import "./themes/dtz/css/dtzLogin.css";
import deMessages from "devextreme/localization/messages/de.json";
import { locale, loadMessages } from "devextreme/localization";
import config from "./config/config.json";
import notify from "devextreme/ui/notify";
import userImg from "./themes/dtz/images/user.webp";

async function validateSession(sessionKey) {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      session: sessionKey,
    },
  };

  return await fetch(
    config.buildtype === "release"
      ? config.destination_release.validatesession
      : config.destination_debug.validatesession,
    requestOptions
  )
    .then(async (response) => {
      const data = await response.json();
      if (!response.ok) {
        const error = (data && data.message) || response.status;
        return Promise.reject(error);
      }
      return data.validatesession_response;
    })
    .catch((error) => {
      notify("Session Validierung fehlgeschlagen! ", "error", 5000);
      console.error("Session Validierung fehlgeschlagen! ", error);
    });
}

function App() {
  const { user, loading, setUserData } = useAuth();
  const [restoreUser, setRestoreUser] = useState(false);
  useEffect(() => {
    (async () => {
      let validationKey = sessionStorage.getItem("key");
      if (validationKey) {
        let validationData = await validateSession(validationKey);
        if (validationData.resultcode === "0") {
          setRestoreUser({
            email: sessionStorage.getItem("name"),
            avatarUrl: userImg,
            key: validationKey,
          });
        }
      }
    })();
  }, [setUserData]);

  if (loading) {
    return <LoadPanel visible={true} />;
  }

  if (user || restoreUser) {
    return <Content />;
  }

  if (!user && !restoreUser) {
    return (
      <div className="dtzLoginContent">
        <LoginContent />
      </div>
    );
  }
}

export default function AppRender() {
  const screenSizeClass = useScreenSizeClass();
  loadMessages(deMessages);
  locale(navigator.language);
  return (
    <Router>
      <AuthProvider>
        <NavigationProvider>
          <div className={`app ${screenSizeClass}`}>
            <App />
          </div>
        </NavigationProvider>
      </AuthProvider>
    </Router>
  );
}
