import React, { useState } from "react";
import TabPanel from "devextreme-react/tab-panel";
import config from "../../config/config.json";
import logo from "../../themes/dtz/images/logo.webp";

import "../../themes/dtz/css/dtzInfo.css";

const tabPanelItems = [
  {
    title: "Info",
    text: "",
    icon: "info",
  },
];

export default function DTZInfo() {
  const [hideInfo, setHideInfo] = useState(false);
  const [hideImpressum, setHideImpressum] = useState(true);

  const onTabSelectionChanged = (e) => {
    switch (e.value) {
      case 0:
        setHideInfo(false);
        setHideImpressum(true);
        break;
      case 1:
        setHideInfo(true);
        setHideImpressum(false);
        break;
      default:
        break;
    }
  };

  return (
    <div className={"dtzInfo"}>
      <TabPanel
        height={"100%"}
        dataSource={tabPanelItems}
        onOptionChanged={onTabSelectionChanged}
      >
        <div hidden={hideInfo}>
          <img src={logo} alt="Logo"></img>
          <br />
          <span className={"dtzInfoHeader"}>
            DTZ Silomanagement {config.version.nummer}
          </span>
          <p className={"dtzInfoText"}>
            Wir helfen als spezialisiertes Systemhaus und Softwareentwickler in
            allen Bereichen moderner Rechentechnik.
            <br />
            Wir kennen die EDV-Themen der Autobranche – und mit zänCAR bieten
            wir Ihnen ein umfassendes Produkt, das Sie in allen
            Unternehmensbereichen unterstützt.
          </p>
          <address className={"addressStyle"}>
            <a
              class="dx-icon-home"
              href="https://www.dtz-systemhaus.de"
              style={{ fontSize: "14px", cursor: "pointer" }}
            >
              {" "}
              www.dtz-systemhaus.de{" "}
            </a>
            <br />
            <br />
            <a
              class="dx-icon-tel"
              href="tel:+49 (0) 3475-9274-0"
              style={{ fontSize: "14px", cursor: "pointer" }}
            >
              {" "}
              +49 (0) 3475-9274-0{" "}
            </a>
            <br />
            <a
              class="dx-icon-print"
              href="tel:+49 (0) 3475-9274-33"
              style={{ fontSize: "14px", cursor: "pointer" }}
            >
              {" "}
              +49 (0) 3475-9274-33{" "}
            </a>
            <br />
            <a
              href="mailto:info@dtzaenker.de"
              class="dx-icon-email"
              style={{ fontSize: "14px", cursor: "pointer" }}
            >
              {" "}
              info@dtzaenker.de{" "}
            </a>
          </address>
          <br />
          <a
            href="https://www.dtz-systemhaus.de/tools/DTZFernwartung.exe"
            class="dx-icon-preferences"
            style={{ fontSize: "14px", cursor: "pointer" }}
          >
            {" "}
            Fernwartung hier starten{" "}
          </a>
        </div>
        <div className={"dtzInfoImpressum"} hidden={hideImpressum}>
          <span className={"dtzInfoHeader"}>Impressum</span>
          <p>
            Datentechnik Zänker <br />
            <br />
            Postanschrift Karl-Rühlemann-Platz 5 <br />
            D-06295 Lutherstadt Eisleben <br />
            Kontakt Tel: +49 (0) 3475-9274-0 <br />
            Fax: +49 (0) 3475-9274-33 <br />
            3475-9274-33 <br />
            info@dtzaenker.de <br />
            www.dtz-systemhaus.de <br />
            <br />
            Geschäftsleitung: Dipl.-Ing. Jürgen Zänker <br />
            Ust-ID-Nummer DE166679293 <br />
            Kammer: IHK Halle/Dessau <br />
            <br />
            Inhaltlich Verantwortlicher gemäß § 10 Absatz 3 MDStV:
            <br />
            <br />
            Jürgen Zänker
            <br />
            Tel: +49 (0) 3475-9274-0
            <br />
            Fax: +49 (0) 3475-9274-33
            <br />
            j.zaenker@dtzaenker.de
            <br />
            <br />
            <span className={"dtzInfoHeader"}>
              Haftungsausschluss und rechtliche Hinweise für die Homepage von
              Datentechnik Zänker (DTZ)
            </span>
            <br />
            <hr />
            <br />
            Auf der DTZ Homepage sind Links zu anderen
            <br />
            Seiten im Internet gesetzt. Für alle diese Links gilt: Trotz
            <br />
            sorgfältiger Prüfung bei der Auswahl der Verlinkung haben wir selbst
            <br />
            keinen Einfluss auf die Inhalte und/oder die Gestaltung der
            <br />
            verlinkten Seiten. Wir übernehmen keinerlei Verantwortung für die
            <br />
            Inhalte von Internet-Seiten, auf deren URL verwiesen wird. Diese
            <br />
            Erklärung gilt für alle auf unserer Website angebrachten Links und
            <br />
            für die Inhalte aller Seiten, zu denen die angebrachten Links
            <br />
            führen. Die Aufnahme von Links auf andere URLs ist ein Service für
            <br />
            die Besucher unserer Website und jederzeit widerrufbar. Dadurch
            <br />
            entsteht kein Rechtsanspruch auf Beibehalt. Wir sind bestrebt, in
            <br />
            allen Darstellungen die Urheberrechte der verwendeten Texte,
            <br />
            Grafiken und Fotos zu beachten bzw. von DTZ selbst erstellte oder
            <br />
            lizenzfreie Texte, Grafiken und Fotos zu nutzen. Sollte dennoch eine
            <br />
            unbeabsichtigte Urheberrechtsverletzung auftreten, werden wir das
            <br />
            entsprechende Objekt nach Benachrichtigung unverzüglich von seiner
            <br />
            Website entfernen bzw. mit dem entsprechenden Urheberrecht kenntlich
            <br />
            machen. Das Urheberrecht für veröffentlichte, von uns selbst
            <br />
            erstellte Objekte bleibt allein bei Datentechnik Zänker. Eine
            <br />
            Vervielfältigung oder Verwendung solcher Texte, Grafiken und Fotos
            <br />
            ist ohne unsere ausdrückliche Zustimmung nicht gestattet. Wir
            <br />
            übernehmen keinerlei Gewähr für die Richtigkeit der bereit
            <br />
            gestellten Informationen. Haftungsansprüche gegen DTZ, welche sich
            <br />
            auf Schäden materieller oder ideeller Art beziehen, die durch die
            <br />
            Nutzung der bereitgestellten Informationen bzw. durch die Nutzung
            <br />
            fehlerhafter oder unvollständiger Informationen verursacht werden,
            <br />
            sind grundsätzlich ausgeschlossen, sofern seitens Datentechnik
            <br />
            Zänker kein nachweislich vorsätzliches oder grob fahrlässiges
            <br />
            Verschulden vorliegt. Alle auf der Website von DTZ genannten und
            <br />
            ggf. durch Dritte geschützten Marken- und Warenzeichen unterliegen
            <br />
            uneingeschränkt den Bestimmungen des jeweils gültigen
            <br />
            Kennzeichenrechts und den Besitzrechten des jeweiligen eingetragenen
            <br />
            Eigentümers. Für die auf unserer Website verwendeten Bilder haben
            <br />
            wir bei verschiedenen Anbietern nicht übertragbare Unterlizenzen
            <br />
            erworben. Die Urheberangaben finden Sie im dezidierten Bildnachweis.
            <br />
          </p>
        </div>
      </TabPanel>
    </div>
  );
}
