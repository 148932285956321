import React, { useMemo, useState } from "react";
import ContextMenu, { Position } from "devextreme-react/context-menu";
import List from "devextreme-react/list";
import { useAuth } from "../../contexts/auth";
import Popup from "devextreme-react/popup";
import DTZSettings from "../../components/dtz/dtzSettings";
import DTZInfo from "../../components/dtz/dtzInfo";
import userImg from "../../themes/dtz/images/user.webp";

import "./user-panel.scss";


export default function UserPanelRender({ menuMode }) {
  const { logOut } = useAuth();
  const [popupSettings, setPopupSettings] = useState(false);
  const [popupInfo, setPopupInfo] = useState(false);


  const menuItems = useMemo(
    () => [
      {
        text: "Info",
        icon: "info",
        onClick: info,
      },
      {
        text: "Einstellungen",
        icon: "preferences",
        onClick: settings,
      },
      {
        text: "Abmelden",
        icon: "runner",
        onClick: logOut,
      },
    ],
    [logOut]
  );

  const renderSettings = () => {
    return (
      <DTZSettings />
    );
  };

  function settings() {
    setPopupSettings({ visible: true });
  };

  function hideSettings() {
    setPopupSettings({ visible: false });
  };

  const renderInfo = () => {
    return(
      <DTZInfo />
    );
  };

  function info(){  
    setPopupInfo({ visible: true });
  };

  function hideInfo() {
    setPopupInfo({ visible: false });
  };

  return (
    <div className={"user-panel"}>
      <div className={"user-info"}>
        <div className={"image-container"}>
          <div
            style={{
              background: `url(${userImg}) no-repeat #fff`,
              backgroundSize: "cover",
            }}
            className={"user-image"}
          />
        </div>
        <div className={"user-name"}>{sessionStorage.getItem("name")}</div>
      </div>

      {menuMode === "context" && (
        <ContextMenu
          items={menuItems}
          target={".user-button"}
          showEvent={"dxclick"}
          width={210}
          cssClass={"user-menu"}
        >
          <Position my={"top center"} at={"bottom center"} />
        </ContextMenu>
      )}
      {menuMode === "list" && (
        <List className={"dx-toolbar-menu-action"} items={menuItems} />
      )}

      <Popup
        visible={popupSettings.visible}
        onHiding={hideSettings}
        dragEnabled={true}
        closeOnOutsideClick={true}
        showTitle={true}
        width={"400px"}
        height={"250px"}
        title={"Einstellungen"}
        resizeEnabled={false}
        contentRender={renderSettings}
        showCloseButton={true}
      />
      <Popup
        visible={popupInfo.visible}
        onHiding={hideInfo}
        dragEnabled={true}
        closeOnOutsideClick={true}
        showTitle={true}
        width={"800px"}
        height={"900px"}
        title={"Info"}
        resizeEnabled={false}
        contentRender={renderInfo}
        showCloseButton={true}
      />
    </div>
  );
}
