import Drawer from "devextreme-react/drawer";
import ScrollView from "devextreme-react/scroll-view";
import React, { useState, useCallback, useRef, useEffect } from "react";
import { Header, Footer } from "../../components";
import "./side-nav-outer-toolbar.scss";
import { useScreenSize } from "../../utils/media-query";
import { Template } from "devextreme-react/core/template";
import { useMenuPatch } from "../../utils/patches";
import List from "devextreme-react/list";
import AnsichtItem from "../../themes/dtz/list/ansichtItem";
import { LoadListData, current_ansicht_id } from "../../Content";
import config from "../../config/config.json";
import notify from "devextreme/ui/notify";
import Menu from "devextreme-react/menu";
import { newRidgeState } from "react-ridge-state";

export const SelectedAnsichtArt = newRidgeState();

export default function SideNavOuterToolbarRender({ title, children }) {
  const scrollViewRef = useRef();
  const { isXSmall, isLarge } = useScreenSize();
  const [patchCssClass] = useMenuPatch();
  const [menuStatus, setMenuStatus] = useState(
    isLarge ? MenuStatus.Opened : MenuStatus.Closed
  );
  const [intervalID, setIntervalID] = useState(-1);

  const messpunktMenu = [
    {
      id: 1,
      name: "Aktualisieren",
      icon: "refresh",
    },
  ];
  const [data, setData] = useState([]);
  async function getData() {
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        session: sessionStorage.getItem("key"),
      },
      body:
        '{"getansicht_request":{"id":"-1","aktiv":"-1","status":"-1","user":"12"}}',
    };
    const response = await fetch(
      config.buildtype === "release"
        ? config.destination_release.ansicht
        : config.destination_debug.ansicht,
      requestOptions
    );
    const data = await response.json();

    let sortBy = [
      {
        prop: "art",
        direction: 1,
      },
      {
        prop: "name",
        direction: 1,
      },
    ];

    let ds = (data.getansicht_response.ansichten = data.getansicht_response.ansichten.sort(
      function (a, b) {
        let i = 0,
          result = 0;
        while (i < sortBy.length && result === 0) {
          result =
            sortBy[i].direction *
            (a[sortBy[i].prop].toString() < b[sortBy[i].prop].toString()
              ? -1
              : a[sortBy[i].prop].toString() > b[sortBy[i].prop].toString()
              ? 1
              : 0);
          i++;
        }
        return result;
      }
    ));

    setData(ds);
  }

  useEffect(() => {
    getData();
  }, []);

  const toggleMenu = useCallback(({ event }) => {
    setMenuStatus((prevMenuStatus) =>
      prevMenuStatus === MenuStatus.Closed
        ? MenuStatus.Opened
        : MenuStatus.Closed
    );
    event.stopPropagation();
  }, []);

  const onMenuClick = (e) => {
    switch (e.itemData.id) {
      case 1:
        try {
          LoadListData(current_ansicht_id, e.itemData.art);
          notify("Aktualisierung erfolgreich!", "success", 2000);
        } catch (error) {
          notify("Aktualisierung fehlgeschlagen!", "error", 3000);
          console.error("Aktualisierung fehlgeschlagen! ", error);
        }
        break;
      default:
        break;
    }
  };
  const onOutsideClick = useCallback(() => {
    setMenuStatus((prevMenuStatus) =>
      prevMenuStatus !== MenuStatus.Closed && !isLarge
        ? MenuStatus.Closed
        : prevMenuStatus
    );
  }, [isLarge]);

  const onAnsichtClick = (e) => {
    const { itemData } = e;

    if (intervalID !== itemData.id) {
      clearInterval(intervalID);
    }

    LoadListData(itemData.id, SelectedAnsichtArt.get() === undefined);
    SelectedAnsichtArt.set(itemData.art);
    setIntervalID(
      setInterval(() => {
        try {
          LoadListData(itemData.id, false);
          notify("Automatische Aktualisierung erfolgreich!", "success", 3000);
        } catch (error) {
          notify("Automatische Aktualisierung fehlgeschlagen!", "error", 5000);
          console.error("Automatische Aktualisierung fehlgeschlagen!", error);
        }
      }, config.timer.listRefreshTimer)
    );
  };

  return (
    <div className={"side-nav-outer-toolbar"}>
      <Header
        className={"layout-header"}
        menuToggleEnabled
        toggleMenu={toggleMenu}
        title={title}
      />
      <Menu
        dataSource={messpunktMenu}
        displayExpr="name"
        onItemClick={onMenuClick}
      ></Menu>
      <span className={"verNum"}>Version {config.version.nummer} </span>
      <Drawer
        className={["drawer", patchCssClass].join(" ")}
        position={"before"}
        closeOnOutsideClick={onOutsideClick}
        openedStateMode={isLarge ? "shrink" : "overlap"}
        revealMode={isXSmall ? "slide" : "expand"}
        minSize={isXSmall ? 0 : 60}
        maxSize={250}
        shading={isLarge ? false : true}
        opened={menuStatus === MenuStatus.Closed ? false : true}
        template={"menu"}
      >
        <div className={"container"}>
          <ScrollView ref={scrollViewRef} className={"layout-body with-footer"}>
            <div className={"content"}>
              {React.Children.map(children, (item) => {
                return item.type !== Footer && item;
              })}
            </div>
            <div className={"content-block"}>
              {React.Children.map(children, (item) => {
                return item.type === Footer && item;
              })}
            </div>
          </ScrollView>
        </div>
        <Template name={"menu"}>
          <React.Fragment>
            <div className="ansicht-container">
              <List
                onItemClick={onAnsichtClick}
                dataSource={data}
                height={"calc(100vh - 60px)"}
                selectionMode={"single"}
                itemRender={AnsichtItem}
                pullRefreshEnabled={false}
                pageLoadMode={"scrollBottom"}
              ></List>
            </div>
          </React.Fragment>
        </Template>
      </Drawer>
    </div>
  );
}

const MenuStatus = {
  Closed: 1,
  Opened: 2,
  TemporaryOpened: 3,
};
