import React, { useState, useRef, useEffect } from "react";

import {
  Chart,
  Series,
  Tooltip,
  Legend,
  ValueAxis,
  ArgumentAxis,
  CommonSeriesSettings,
  CommonAxisSettings,
  Label,
  Grid,
  ConstantLine,
  Point,
} from "devextreme-react/chart";
import Popup from "devextreme-react/popup";
import moment from "moment";
import TabPanel from "devextreme-react/tab-panel";
import DataGrid, { Column, Export, Paging } from "devextreme-react/data-grid";
import DropDownButton from "devextreme-react/drop-down-button";
import "../../themes/dtz/css/dtzDetailAnsicht.css";
import { Font } from "devextreme-react/circular-gauge";
import notify from "devextreme/ui/notify";
import dec2bin from "./dtzMath.js";
import DTZAnzahlMesswerte from "./dtzAnzahlMesswerte.js";
import DTZZeitraum from "./dtzZeitraum.js";
import config from "../../config/config.json";
import { createBrowserHistory } from "history";

const tabPanelItems = [
  {
    title: "Messwerte",
    text: "",
    icon: "chart",
  },
  {
    title: "Eigenschaften",
    text: "",
    icon: "detailslayout",
  },
];

const messwerteOptionen = [
  { name: "Zeitraum", icon: "clock" },
  { name: "Anzahl Messwerte", icon: "add" },
  { template: () => "<hr class='is-menu-separator-ct' />" },
  { name: "Export", icon: "xlsxfile" },
];

function MedianToBool(v) {
  let bMedian = false;
  if (v === "1") bMedian = true;
  return bMedian;
}

function mittelwert_ds(data) {
  let currentMW = 0;
  let counter = 0;
  data.forEach(function (mw) {
    if (mw.verbrauch2 > 0) {
      currentMW = parseInt(currentMW) + parseInt(mw.verbrauch2);
      counter++;
    }
  });
  if (counter > 0) currentMW = parseInt(currentMW / counter);
  if (counter === 0) currentMW = 0;
  return Math.trunc(currentMW);
}

async function loadHistorieZeitraum(anzahl, messpunkte, datumVon, datumBis) {
  const requestOptions_historie = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      session: sessionStorage.getItem("key"),
    },
    body:
      '{"getmesswertehistorie_request":{"messpunkte":[' +
      messpunkte +
      '],"zeitvon":"' +
      datumVon +
      '","zeitbis":"' +
      datumBis +
      '"' +
      "}}",
  };

  return await fetch(
    config.buildtype === "release"
      ? config.destination_release.historie
      : config.destination_debug.historie,
    requestOptions_historie
  )
    .then(async (response) => {
      let data = await response.json();
      if (!response.ok) {
        const error = (data && data.message) || response.status;
        return Promise.reject(error);
      }
      let historie = data.getmesswertehistorie_response.messwerte;
      let currentHistorie = [];
      let i = 0;
      historie.forEach(function (el_historie) {
        if (messpunkte[0] === el_historie.messpunkt) {
          let zeitmessung = el_historie.zeitmessung;
          let wert = parseFloat(el_historie.wert);
          let verbrauch = parseFloat(el_historie.verbrauch);
          let verbrauch2 = el_historie.verbrauch2
            ? parseFloat(el_historie.verbrauch2)
            : 0;
          let wertmedian = el_historie.wertmedian
            ? parseFloat(el_historie.wertmedian)
            : 0;
          let verbrauchmedian2 = el_historie.verbrauchmedian2
            ? parseFloat(el_historie.verbrauchmedian2)
            : 0;
          let verbrauchmedian = el_historie.verbrauchmedian
            ? parseFloat(el_historie.verbrauchmedian)
            : 0;

          currentHistorie.push({
            index: i,
            Zeit: zeitmessung,
            Wert: wert,
            Verbrauch: verbrauch,
            verbrauch2: verbrauch2,
            wertmedian: wertmedian,
            verbrauchmedian: verbrauchmedian,
            verbrauchmedian2: verbrauchmedian2,
          });
          i++;
        }
      });
      notify(anzahl + " Werte erfolgreich geladen!", "success", 2000);
      return currentHistorie;
    })
    .catch((error) => {
      notify("Historienwerte konnten nicht geladen werden!", "error", 5000);
      console.error("Historienwerte konnten nicht geladen werden! ", error);
      createBrowserHistory().push("/#login");
      window.location.href.reload();
    });
}

async function loadHistorieAnzahl(anzahl, messpunkte) {
  const requestOptions_historie = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      session: sessionStorage.getItem("key"),
    },
    body:
      '{"getmesswertehistorie_request":{"messpunkte":[' +
      messpunkte +
      '],"anzahl":"' +
      anzahl +
      '"}}',
  };

  return await fetch(
    config.buildtype === "release"
      ? config.destination_release.historie
      : config.destination_debug.historie,
    requestOptions_historie
  )
    .then(async (response) => {
      let data = await response.json();
      if (!response.ok) {
        const error = (data && data.message) || response.status;
        return Promise.reject(error);
      }
      let historie = data.getmesswertehistorie_response.messwerte;
      let currentHistorie = [];
      let i = 0;
      historie.forEach(function (el_historie) {
        if (messpunkte[0] === el_historie.messpunkt) {
          let zeitmessung = el_historie.zeitmessung;
          let wert = parseFloat(el_historie.wert);
          let verbrauch = parseFloat(el_historie.verbrauch);
          let verbrauch2 = el_historie.verbrauch2
            ? parseFloat(el_historie.verbrauch2)
            : 0;
          let wertmedian = el_historie.wertmedian
            ? parseFloat(el_historie.wertmedian)
            : 0;
          let verbrauchmedian2 = el_historie.verbrauchmedian2
            ? parseFloat(el_historie.verbrauchmedian2)
            : 0;
          let verbrauchmedian = el_historie.verbrauchmedian
            ? parseFloat(el_historie.verbrauchmedian)
            : 0;

          currentHistorie.push({
            index: i,
            Zeit: zeitmessung,
            Wert: wert,
            Verbrauch: verbrauch,
            verbrauch2: verbrauch2,
            wertmedian: wertmedian,
            verbrauchmedian: verbrauchmedian,
            verbrauchmedian2: verbrauchmedian2,
          });
          i++;
        }
      });
      notify(anzahl + " Werte erfolgreich geladen!", "success", 2000);
      return currentHistorie;
    })
    .catch((error) => {
      notify("Historienwerte konnten nicht geladen werden!", "error", 5000);
      console.error("Historienwerte konnten nicht geladen werden! ", error);
      createBrowserHistory().push("/#login");
      window.location.reload();
    });
}

export default function DTZPopup(props) {
  let refDataGrid = useRef(null);
  let refAnzahlMesswerte = useRef(null);
  let refZeitraum = useRef(null);
  const [hideMesswerte, setHideMesswerte] = useState(false);
  const [hideEigenschaften, setHideEigenschaften] = useState(true);
  const [popupAnzahlMesswerte, setPopupAnzahlMesswerte] = useState(false);
  const [popupZeitraum, setPopupZeitraum] = useState(false);
  const [historie, setHistorie] = useState(props.messpunkt.historie);
  const [mittelwert, setMittelwert] = useState(props.messpunkt.mittelwert);
  const [verbrauchmax, setVerbrauchMax] = useState(
    props.messpunkt.verbrauchmax
  );

  let colorLinieMedian = "#F78119";
  let showMedian = false;
  let colorLinieWert = "#21BD28";
  let colorAreaWert = "#0080ff";
  let colorAreaMedian = "#0080ff";
  let currentColorArea = colorAreaWert;
  let currentColorLinie = colorLinieMedian;
  let currentArea = "Wert";
  let currentLinie = "wertmedian";
  //let currentBar = "Verbrauch";
  //let colorVerbrauch2Bar = "#F78119";

  let verbrauch1Bar = "Verbrauch";
  //let verbrauch2Bar = "verbrauch2";
  let verbrauchmedianBar = "verbrauchmedian";
  let verbrauchmedian2Bar = "verbrauchmedian2";
  let currentVerbrauchKeyBar = verbrauch1Bar;

  let mediankz = dec2bin(props.messpunkt.median);
  mediankz = mediankz.split("").reverse().join("");
  CheckMedianKZ(mediankz);

  useEffect(() => {
    setHistorie(props.messpunkt.historie);
    setMittelwert(props.messpunkt.mittelwert);
    setVerbrauchMax(props.messpunkt.verbrauchmax);
    refDataGrid.current.instance.columnOption("index", {
      sortOrder: "asc",
    });
  }, [props]);

  function onTabSelectionChanged(e) {
    switch (e.value) {
      case 0:
        setHideMesswerte(false);
        setHideEigenschaften(true);
        break;
      case 1:
        setHideMesswerte(true);
        setHideEigenschaften(false);
        break;
      default:
        break;
    }
  }

  function chartCustomizeText(arg) {
    if (arg.value >= 1000) {
      return arg.value / 1000 + "t";
    }
  }

  function gridCustomizeWert(cellInfo) {
    return cellInfo.value.toLocaleString(undefined, {
      maximumFractionDigits: 2,
    });
  }

  function onOptionsClick(e) {
    switch (e.itemData.name) {
      case "Export":
        refDataGrid.current.instance.exportToExcel();
        break;
      case "Anzahl Messwerte":
        setPopupAnzahlMesswerte({ visible: true });
        break;
      case "Zeitraum":
        setPopupZeitraum({ visible: true });
        break;
      default:
        break;
    }
  }
  function CheckMedianKZ(kz) {
    for (let x = 0; x < kz.length; x++) {
      switch (x) {
        case 0: //Median als Linie
          if (MedianToBool(kz[x])) showMedian = true;
          break;
        case 1: //Median als Fläche
          if (MedianToBool(kz[x])) {
            currentArea = "wertmedian";
            currentColorArea = colorAreaMedian;
            currentVerbrauchKeyBar = verbrauchmedian2Bar;
          }
          break;
        case 3: //Median als akt. Wert
          if (MedianToBool(kz[x])) currentArea = "wertmedian";
          currentVerbrauchKeyBar = verbrauchmedianBar;
          break;
        case 4: //aktuellen Wert als Linie
          if (MedianToBool(kz[x])) {
            currentLinie = "Wert";
            currentColorLinie = colorLinieWert;
            currentVerbrauchKeyBar = verbrauchmedianBar;
          }
          showMedian = true;
          break;
        default:
          break;
      }
    }
  }

  async function onAnzahlChanged(val) {
    let currentHistorie = await loadHistorieAnzahl(val, [props.messpunkt.id]);
    let currentMittelwert = mittelwert_ds(currentHistorie);
    setMittelwert(currentMittelwert);
    setHistorie(currentHistorie.reverse());
    refDataGrid.current.instance.columnOption("index", {
      sortOrder: "asc",
    });
    refAnzahlMesswerte.current.instance.hide();
  }

  async function onDateChanged(val) {
    let currentHistorie = await loadHistorieZeitraum(
      24,
      [props.messpunkt.id],
      val[0],
      val[1]
    );
    let currentMittelwert = mittelwert_ds(currentHistorie);
    setMittelwert(currentMittelwert);
    setHistorie(currentHistorie.reverse());
    refDataGrid.current.instance.columnOption("index", {
      sortOrder: "asc",
    });
    refZeitraum.current.instance.hide();
  }

  const renderAnzahlMesswerte = () => {
    return <DTZAnzahlMesswerte anzahl={onAnzahlChanged} />;
  };

  const renderZeitraum = () => {
    return <DTZZeitraum datum={onDateChanged} />;
  };

  function hideAnzahlMesswerte() {
    setPopupAnzahlMesswerte({ visible: false });
  }

  function hidePopupZeitraum() {
    setPopupZeitraum({ visible: false });
  }

  function customizeChartTooltip(arg) {
    return {
      text:
        arg.value.toLocaleString(undefined, {
          maximumFractionDigits: 2,
        }) +
        " " +
        props.messpunkt.einheittext +
        "<br/>" +
        arg.argument +
        " Uhr",
    };
  }

  function onDropDownShown(e) {
    let item = e.component
      .content()
      .querySelector(
        ".dx-scrollview-content > div.dx-state-disabled.dx-item.dx-list-item"
      );
    item.style.display = "contents";

    item = e.component
      .content()
      .querySelector(
        ".dx-scrollview-content > div.dx-state-disabled.dx-item.dx-list-item > div.dx-item-content"
      );
    item.style.display = "inherit";
    item.style.padding = "unset";
    e.component = item;
    return e;
  }

  return (
    <div id="dtzDetailAnsicht">
      <TabPanel
        height={260}
        dataSource={tabPanelItems}
        onOptionChanged={onTabSelectionChanged}
      />
      <div className="dtzDetailMesswerteAnsicht" hidden={hideMesswerte}>
        <Chart
          className="dtzDetailAnsichtChart"
          title={"Messwerte"}
          dataSource={historie}
        >
          <Series
            argumentField="Zeit"
            valueField={currentArea}
            type="area"
            color={currentColorArea}
          />
          <Series
            argumentField="Zeit"
            valueField={currentLinie}
            type="spline"
            width={3}
            color={currentColorLinie}
            visible={showMedian}
          />
          <Tooltip
            enabled={true}
            location="edge"
            customizeTooltip={customizeChartTooltip}
          />
          <CommonAxisSettings>
            <Grid visible={true}></Grid>
          </CommonAxisSettings>
          <CommonSeriesSettings opacity={0.8}>
            <Point visible={false} />
          </CommonSeriesSettings>
          <ValueAxis
            valueMarginsEnabled={false}
            visualRange={[0, props.messpunkt.wertmax]}
          >
            <Label visible={true} customizeText={chartCustomizeText}></Label>
          </ValueAxis>
          <ArgumentAxis valueMarginsEnabled={false} tickInterval={10}>
            <Label
              visible={true}
              displayMode={"rotate"}
              rotationAngle={-90}
            ></Label>
          </ArgumentAxis>
          <Tooltip
            enabled={true}
            location="edge"
            customizeTooltip={customizeChartTooltip}
          />
          <Legend visible={false}>
            <ValueAxis valueType="numeric"></ValueAxis>
          </Legend>
        </Chart>
        <Chart
          className="dtzDetailAnsichtBarChart"
          dataSource={historie}
          title={"Verbrauch"}
        >
          <Series
            argumentField="Zeit"
            valueField={currentVerbrauchKeyBar}
            type="bar"
            color="#0080ff"
          />
          <Tooltip
            enabled={true}
            location="edge"
            customizeTooltip={customizeChartTooltip}
          />
          <CommonAxisSettings>
            <Grid visible={true}>
              <Column
                dataField="Zeit"
                caption="Zeit"
                dataType="number"
                alignment="center"
              />
            </Grid>
          </CommonAxisSettings>
          <CommonSeriesSettings opacity={0.8}>
            <Point visible={false} />
          </CommonSeriesSettings>
          <ValueAxis tickInterval={1000} visualRange={[0, verbrauchmax]}>
            <Label visible={true} customizeText={chartCustomizeText}></Label>
            <ConstantLine
              width={2}
              value={mittelwert ? mittelwert : 0}
              color="#e61919"
              dashStyle="dash"
            >
              <Label
                className={"dtzDetailAnsichtMittelwertText"}
                text={
                  "Mittelwert " +
                  parseInt(mittelwert ? mittelwert : 0).toLocaleString(undefined, {
                    maximumFractionDigits: 2,
                  }) +
                  " " +
                  props.messpunkt.einheittext
                }
              >
                <Font size={"14px"} color={"black"}></Font>
              </Label>
            </ConstantLine>
          </ValueAxis>
          <ArgumentAxis valueMarginsEnabled={true}>
            <Label visible={false}></Label>
          </ArgumentAxis>
          <Legend visible={false}>
            <ValueAxis valueType="numeric"></ValueAxis>
          </Legend>
        </Chart>
        <DataGrid
          ref={refDataGrid}
          className="dtzDetailAnsichtDataGrid"
          dataSource={historie}
          // defaultColumns={["Zeit", "Wert", "Verbrauch"]}
          showBorders={true}
        >
          <Paging enabled={false} />
          <Column
            dataField="index"
            text="Idx"
            alignment="center"
            visible={false}
          />
          <Column
            dataField="Zeit"
            text="Zeit (Messung)"
            alignment="center"
            allowSorting={false}
          />
          <Column
            dataField="Wert"
            customizeText={gridCustomizeWert}
            allowSorting={false}
          />
          <Column
            dataField={"Verbrauch"}
            customizeText={gridCustomizeWert}
            caption={"Verbrauch"}
            allowSorting={false}
          />
          <Export
            fileName={
              props.messpunkt.firmatext +
              " - " +
              props.messpunkt.standorttext +
              " - " +
              props.messpunkt.name +
              " - " +
              props.messpunkt.inhalttext +
              " - " +
              moment().format("DD_MM_YYYY HH_mm")
            }
          ></Export>
        </DataGrid>
        <DropDownButton
          className="dtzDetailAnsichtButtonAnzahlMesswerte"
          text="Optionen"
          icon="preferences"
          dropDownOptions={{ width: 230, onShown: { onDropDownShown } }}
          items={messwerteOptionen}
          displayExpr="name"
          onItemClick={onOptionsClick}
        />
        <span className="dtzDetailAnsichtMittelwert" hidden>
          Mittelwert:
          {mittelwert}
          <div className="dtzDetailAnsichtRectangle"></div>
        </span>
      </div>
      <div className="dtzDetailMesswerteAnsicht" hidden={hideEigenschaften}>
        <div className="dtzDetailEigenschaftBlock">
          <div className="dtzDetailEigenschaftItem">
            <span className="dtzDetailEigenschaftHeader">letzter Wert</span>
            <span className="dtzDetailEigenschaftValue">
              {props.messpunkt
                ? parseInt(props.messpunkt.wert).toLocaleString(undefined, {
                    maximumFractionDigits: 2,
                  }) +
                  " " +
                  props.messpunkt.einheittext
                : "k.A."}
            </span>
            <div className="dtzDetailEigenschaftItem">
              <span className="dtzDetailEigenschaftHeader">letzte Messung</span>
              <span className="dtzDetailEigenschaftValue">
                {props.messpunkt ? props.messpunkt.letzterwert : "k.A."}
              </span>
            </div>
            <div className="dtzDetailEigenschaftItem">
              <span className="dtzDetailEigenschaftHeader">Prognose</span>
              <span className="dtzDetailEigenschaftValue">
                {props.messpunkt ? props.messpunkt.prognosewert : "k.A."}
              </span>
            </div>
            <div className="dtzDetailEigenschaftItem">
              <span className="dtzDetailEigenschaftHeader">Alarmgrenze</span>
              <span className="dtzDetailEigenschaftValue">
                {props.messpunkt
                  ? props.messpunkt.alarmwertgrenze +
                    " " +
                    props.messpunkt.einheittext
                  : "k.A."}
              </span>
            </div>
            <div className="dtzDetailEigenschaftItem">
              <span className="dtzDetailEigenschaftHeader">Alarmstatus</span>
              <span className="dtzDetailEigenschaftValue">
                {props.messpunkt
                  ? props.messpunkt.status === "0"
                    ? "in Ordnung"
                    : props.messpunkt.status === "1"
                    ? "Alarm"
                    : props.messpunkt.status === "2"
                    ? "Fehler"
                    : "unbekannter Wert"
                  : "k.A."}
              </span>
            </div>
          </div>
        </div>
      </div>
      <Popup
        ref={refAnzahlMesswerte}
        visible={popupAnzahlMesswerte.visible}
        onHiding={hideAnzahlMesswerte}
        dragEnabled={true}
        closeOnOutsideClick={true}
        showTitle={true}
        width={"400px"}
        height={"150px"}
        title={"Anzahl Messwerte"}
        resizeEnabled={false}
        contentRender={renderAnzahlMesswerte}
        showCloseButton={true}
      />
      <Popup
        ref={refZeitraum}
        visible={popupZeitraum.visible}
        onHiding={hidePopupZeitraum}
        dragEnabled={true}
        closeOnOutsideClick={true}
        showTitle={true}
        width={"400px"}
        height={"250px"}
        title={"Zeitraum"}
        resizeEnabled={false}
        contentRender={renderZeitraum}
        showCloseButton={true}
      />
    </div>
  );
}
