import React, { useState, useEffect, useRef } from "react";
import { AppInfo } from "./app-info";
import List from "devextreme-react/list";
import MesspunktItem from "./components/dtz/messpunkt_item.js";
import DataSource from "devextreme/data/data_source";
import Popup from "devextreme-react/popup";
import notify from "devextreme/ui/notify";
import "./themes/dtz/css/dtzPopup.css";
import "./themes/dtz/css/dtzMesspunktItem.css";

import { SelectedAnsichtArt } from "./layouts/side-nav-outer-toolbar/side-nav-outer-toolbar";
import config from "./config/config.json";
import DataGrid, { Column, Paging } from "devextreme-react/data-grid";
import { Switch, Route, Redirect } from "react-router-dom";
import { SideNavOuterToolbar as SideNavBarLayout } from "./layouts";
import { createBrowserHistory } from "history";
import bgImg from "./themes/dtz/images/bg_hell.webp";
import DTZPopupContent from "./components/dtz/dtzDetailAnsicht.js";
export var current_ansicht_id = undefined;

let popupHeading = "Titel";
let currentMesspunktData = undefined;

function mittelwert_ds(data) {
  let currentMW = 0;
  let counter = 0;
  data.forEach(function (mw) {
    if (mw.verbrauch2 > 0) {
      currentMW = parseInt(currentMW) + parseInt(mw.verbrauch2);
      counter++;
    }
  });
  if (counter > 0) currentMW = parseInt(currentMW / counter);
  if (counter === 0) currentMW = 0;
  return Math.trunc(currentMW);
}

const ds_messpunkte = new DataSource({
  paginate: false,
  load: (loadOptions) => {
    if (current_ansicht_id !== undefined) {
      const requestOptions_messpunkte = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          session: sessionStorage.getItem("key"),
        },
        body:
          '{"getmesspunkte_request":{"aktiv":"-1","ansicht":"' +
          current_ansicht_id +
          '","status":"-1"}}',
      };
      return fetch(
        config.buildtype === "release"
          ? config.destination_release.messpunkte
          : config.destination_debug.messpunkte,
        requestOptions_messpunkte
      )
        .then(async (response) => {
          const data = await response.json();
          if (!response.ok) {
            const error = (data && data.message) || response.status;
            return Promise.reject(error);
          }

          let mp = data.getmesspunkte_response.messpunkte;

          let messpunkte = [];
          for (let x = 0; x < mp.length; x++) {
            messpunkte.push('"' + mp[x].id + '"');
          }

          const requestOptions_historie = {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              session: sessionStorage.getItem("key"),
            },
            body:
              '{"getmesswertehistorie_request":{"messpunkte":[' +
              messpunkte +
              '],"anzahl":"' +
              24 +
              '"}}',
          };
          return fetch(
            config.buildtype === "release"
              ? config.destination_release.historie
              : config.destination_debug.historie,
            requestOptions_historie
          )
            .then(async (response) => {
              let data = await response.json();
              if (!response.ok) {
                const error = (data && data.message) || response.status;
                return Promise.reject(error);
              }
              let historie = data.getmesswertehistorie_response.messwerte;
              let i = 0;
              mp.forEach(function (el_mp, index) {
                let currentHistorie = [];
                historie.forEach(function (el_historie) {
                  if (el_mp.id === el_historie.messpunkt) {
                    let zeitmessung = el_historie.zeitmessung;
                    let wert = parseFloat(el_historie.wert);
                    let verbrauch = parseFloat(el_historie.verbrauch);
                    let verbrauch2 = el_historie.verbrauch2
                      ? parseFloat(el_historie.verbrauch2)
                      : 0;
                    let wertmedian = el_historie.wertmedian
                      ? parseFloat(el_historie.wertmedian)
                      : 0;
                    let verbrauchmedian2 = el_historie.verbrauchmedian2
                      ? parseFloat(el_historie.verbrauchmedian2)
                      : 0;
                    let verbrauchmedian = el_historie.verbrauchmedian
                      ? parseFloat(el_historie.verbrauchmedian)
                      : 0;

                    currentHistorie.push({
                      index: i,
                      Zeit: zeitmessung,
                      Wert: wert < 0 ? 0 : wert,
                      Verbrauch: verbrauch,
                      verbrauch2: verbrauch2,
                      wertmedian: wertmedian,
                      verbrauchmedian: verbrauchmedian,
                      verbrauchmedian2: verbrauchmedian2,
                    });
                    i++;
                  }
                });
                mp[index].mittelwert = mittelwert_ds(currentHistorie);
                currentHistorie = currentHistorie.reverse();
                mp[index].historie = currentHistorie;
                let verbrauchmax = mp[index].verbrauchmax;

                let verbrauchmaxMultiplier =
                  parseInt(verbrauchmax) === 0 ||
                  parseInt(verbrauchmax) === undefined
                    ? 0.07
                    : 1;
                let max = 0;
                if (
                  parseInt(verbrauchmax) === 0 ||
                  parseInt(verbrauchmax) === undefined
                ) {
                  max = mp[index].wertmax * verbrauchmaxMultiplier;
                } else {
                  max = verbrauchmax;
                }
                mp[index].verbrauchmax = Math.trunc(max);
              });

              return {
                data: mp,
                totalCount: mp.length,
              };
            })
            .catch((error) => {
              notify(
                "Historienwerte konnten nicht geladen werden!",
                "error",
                5000
              );
              console.error(
                "Historienwerte konnten nicht geladen werden! ",
                error
              );
              createBrowserHistory().push("/#login");
              window.location.reload();
            });
        })
        .catch((error) => {
          notify("Messpunkte konnten nicht geladen werden!", "error", 5000);
          createBrowserHistory().push("/#login");
          window.location.reload();
          console.error("Messpunkte konnten nicht geladen werden! ", error);
        });
    }
  },
});

export const LoadListData = (id, isFirstLoad) => {
  current_ansicht_id = id;
  if (isFirstLoad !== true) ds_messpunkte.reload();
};

export default function ContentRender() {
  const refAnsichten = useRef(null);
  const ansichtArt = SelectedAnsichtArt.useValue();

  const [backgroundShow, setBackgroundShow] = useState({
    value: localStorage.getItem("backgroundShow"),
  });
  const [backgroundOpacity, setBackgroundOpacity] = useState({
    value: localStorage.getItem("backgroundOpacity"),
  });
  const [popupTitle, setPopupTitle] = useState(0);
  const [popupDetails, setPopupDetails] = useState(true);

  useEffect(() => {
    let bgOpacity = undefined,
      bgShow = undefined;

    bgOpacity =
      localStorage.getItem("backgroundOpacity") !== null
        ? localStorage.getItem("backgroundOpacity") / 100
        : 0.25;
    bgShow =
      localStorage.getItem("backgroundShow") !== null
        ? localStorage.getItem("backgroundShow")
        : "1";

    setBackgroundOpacity({
      value: bgOpacity,
    });

    setBackgroundShow({
      value: bgShow,
    });
    SelectedAnsichtArt.reset();
  }, []);

  const onMesspunktClick = (e) => {
    currentMesspunktData = e.itemData;
    popupHeading =
      currentMesspunktData.firmatext +
      " - " +
      currentMesspunktData.standorttext +
      " - " +
      currentMesspunktData.name +
      " - " +
      currentMesspunktData.inhalttext;
    setPopupTitle({ title: popupHeading });
    setPopupDetails({ visible: true });
  };

  const onRowClick = (e) => {
    currentMesspunktData = e.data;
    popupHeading =
      currentMesspunktData.firmatext +
      " - " +
      currentMesspunktData.standorttext +
      " - " +
      currentMesspunktData.name +
      " - " +
      currentMesspunktData.inhalttext;

    setPopupTitle({ title: popupHeading });
    setPopupDetails({ visible: true });
  };

  const hideInfo = () => {
    currentMesspunktData = {};
    setPopupDetails({ visible: false });
  };
  const prepareGridWert = (e) => {
    return <span className={"aktuellerWertAlarm"}>{e.value}</span>;
  };

  const cellThousandsSeperator = (e) => {
    var num_parts = e.value.toString().split(".");
    num_parts[0] = num_parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    return num_parts.join(".");
  };

  return (
    <Switch>
      <Route exact path="/">
        <SideNavBarLayout
          title={AppInfo.title}
          className="dtzMesspunktBackground"
          style={{
            backgroundImage: 'url("./themes/dtz/images/bg_hell.webp")'
          }}
        >
          <img
            src={bgImg}
            style={{
              opacity: backgroundOpacity.value,
              visibility: backgroundShow.value === "1" ? "visible" : "hidden",
              //  marginTop: "calc((100vh - 89px) * -1)",
              position: "fixed",
              height: "100vh",
            }}
            alt=" "
          />
          <div className="dtzMesspunktListe">
            {ansichtArt === "110100" && (
              <List
                onItemClick={onMesspunktClick}
                dataSource={ds_messpunkte}
                height={"99%"}
                repaintChangesOnly={true}
                selectionMode={"single"}
                ref={refAnsichten}
                itemRender={MesspunktItem}
                // style={{ backgroundcolor: "transparent" }}
                noDataText={"Keine Ansicht ausgewählt"}
                pageLoadMode={"scrollBottom"}
              />
            )}
            {ansichtArt === "100100" && (
              <DataGrid
                className="messpunktGrid"
                dataSource={ds_messpunkte}
                onCellPrepared={prepareGridWert}
                // defaultColumns={columns}
                showBorders={true}
                onRowClick={onRowClick}
                hoverStateEnabled={true}
              >
                <Paging enabled={false} />
                <Column dataField="firmatext" caption={"Kunde"} />
                <Column dataField="standorttext" caption={"Standort"} />
                <Column dataField="name" caption={"Messpunkt"} />
                <Column dataField="inhalttext" caption={"Inhalt"} />
                <Column
                  dataField="alarmwertgrenze"
                  caption={"Alarmgrenze"}
                  alignment={"right"}
                  customizeText={cellThousandsSeperator}
                />
                <Column
                  dataField="wert"
                  caption={"aktueller Wert"}
                  cssClass="aktuellerWertAlarm"
                  alignment={"right"}
                  customizeText={cellThousandsSeperator}
                />
                <Column dataField="einheittext" caption={"Einheit"} />
                <Column
                  dataField="letzterwert"
                  caption={"Zeitpunkt"}
                  alignment={"center"}
                />
                <Column
                  dataField="verbrauch"
                  caption={"Verbrauch"}
                  alignment={"right"}
                  customizeText={cellThousandsSeperator}
                />
              </DataGrid>
            )}
            {ansichtArt === "100200" && (
              <DataGrid
                className="messpunktGrid"
                dataSource={ds_messpunkte}
                // defaultColumns={columns}
                showBorders={true}
                hoverStateEnabled={true}
                onRowClick={onRowClick}
              >
                <Paging enabled={false} />
                <Column dataField="firmatext" caption={"Kunde"} />
                <Column dataField="standorttext" caption={"Standort"} />
                <Column dataField="name" caption={"Messpunkt"} />
                <Column dataField="inhalttext" caption={"Inhalt"} />
                <Column
                  dataField="alarmwertgrenze"
                  caption={"Alarmgrenze"}
                  customizeText={cellThousandsSeperator}
                />
                <Column
                  dataField="wert"
                  caption={"aktueller Wert"}
                  cssClass={".aktuellerWertAlarm"}
                  alignment={"right"}
                  customizeText={cellThousandsSeperator}
                />
                <Column dataField="einheittext" caption={"Einheit"} />
                <Column
                  dataField="letzterwert"
                  caption={"Zeitpunkt"}
                  alignment={"center"}
                />
                <Column
                  dataField="verbrauch"
                  caption={"Verbrauch"}
                  alignment={"right"}
                  customizeText={cellThousandsSeperator}
                />
              </DataGrid>
            )}
            {ansichtArt === "100300" && (
              <DataGrid
                className="messpunktGrid"
                hoverStateEnabled={true}
                dataSource={ds_messpunkte}
                showBorders={true}
                onRowClick={onRowClick}
              >
                <Paging enabled={false} />
                <Column dataField="firmatext" caption={"Kunde"} />
                <Column dataField="standorttext" caption={"Standort"} />
                <Column dataField="name" caption={"Messpunkt"} />
                <Column dataField="inhalttext" caption={"Inhalt"} />
                <Column
                  dataField="prognosebasis"
                  caption={"Prognosewert"}
                  customizeText={cellThousandsSeperator}
                  alignment={"right"}
                />
                <Column
                  dataField="prognosewert"
                  caption={"Prognose Zeitpunkt"}
                  cssClass={".aktuellerWertAlarm"}
                  alignment={"center"}
                />
                <Column
                  dataField="wert"
                  caption={"aktueller Wert"}
                  alignment={"right"}
                  customizeText={cellThousandsSeperator}
                />
                <Column dataField="einheittext" caption={"Einheit"} />
                <Column
                  dataField="letzterwert"
                  caption={"Zeitpunkt"}
                  alignment={"center"}
                />
                <Column
                  dataField="verbrauch"
                  caption={"Verbrauch/h"}
                  alignment={"right"}
                  customizeText={cellThousandsSeperator}
                />
              </DataGrid>
            )}
          </div>
        </SideNavBarLayout>
        <Popup
          visible={popupDetails.visible}
          onHiding={hideInfo}
          dragEnabled={true}
          closeOnOutsideClick={true}
          showTitle={true}
          width={"1600px"}
          height={"910px"}
          title={popupTitle.title}
          resizeEnabled={true}
          showCloseButton={true}
          //contentRender={renderPopupDetails}
        >
          <DTZPopupContent
            messpunkt={currentMesspunktData ? currentMesspunktData : -1}
          ></DTZPopupContent>
        </Popup>
      </Route>
      <Redirect to={"/dashboard"} />
    </Switch>
  );
}
