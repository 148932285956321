import Slider from "devextreme-react/slider";
import React, { useRef, useState } from "react";
import { Button } from "devextreme-react/button";

import "../../themes/dtz/css/dtzAnzahlMesswerte.css";

export default function DTZAnzahlMesswerte(props) {
  let refSlider = useRef();
  const [anzahl, setAnzahl] = useState(24);

  function onUebernehmen() {
    props.anzahl(anzahl);
  }

  function handleAnzahlChange(e){
    setAnzahl(e.value);
  }

  return (
    <div className={"dtzAnzahlMesswerte"}>
      <Slider
        ref={refSlider}
        min={0}
        max={100}
        defaultValue={24}
        value={anzahl}
        onValueChanged={handleAnzahlChange}
        tooltip={{
          enabled: true,
          showMode: "always",
          position: "bottom",
        }}
      />
      <Button
        className="dtzAnzahlMesswerteButton"
        text="Übernehmen"
        stylingMode="contained"
        onClick={onUebernehmen}
      />
    </div>
  );
}
