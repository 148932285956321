import React from "react";
import moment from "moment";

import "../../themes/dtz/css/dtzMesspunktItem.css";
import check from "../../themes/dtz/images/status/check.png";
import warning from "../../themes/dtz/images/status/warning.png";
import cross from "../../themes/dtz/images/status/cross.png";

import dec2bin from "./dtzMath.js";

import {
  CircularGauge,
  Scale,
  Label,
  RangeContainer,
  Range,
  ValueIndicator,
  Geometry,
  Export,
} from "devextreme-react/circular-gauge";

import {
  Chart,
  Series,
  Tooltip,
  Legend,
  ValueAxis,
  ArgumentAxis,
  CommonSeriesSettings,
  CommonAxisSettings,
  Grid,
  Point,
  Tick,
  Title,
} from "devextreme-react/chart";
import FadeIn from "react-fade-in";

//let currentEinheitText = undefined;

function gaugeText(arg) {
  return {
    text: Math.trunc(arg.value) + " %",
  };
}

function MedianToBool(v) {
  let bMedian = false;
  if (v === "1") bMedian = true;
  return bMedian;
}

export default function MesspunktItem(item) {
  //let detailsVisible = false;
  let showMedian = false;
  let colorLinieMedian = "#F78119";
  let colorLinieWert = "#21BD28";
  let colorAreaWert = "#0080ff";
  let colorAreaMedian = "#0080ff";
  let currentColorArea = colorAreaWert;
  let currentColorLinie = colorLinieMedian;
  let currentArea = "Wert";
  let currentLinie = "wertmedian";

  let mediankz = dec2bin(item.median);
  mediankz = mediankz.split("").reverse().join("");
  CheckMedianKZ(mediankz);

  let statusSrc = check_status(item.status);
  let currentPrognose = "";
  let prognoseHeading = "Prognose";
  let currentPrognoseWert = "";
  let wert = parseInt(item.wert).toLocaleString(undefined, {
    maximumFractionDigits: 2,
  });
  let wertmax = parseInt(item.wertmax).toLocaleString(undefined, {
    maximumFractionDigits: 2,
  });

  let today = moment();
  let tomorrow = moment().add(1, "days").format("DD-MM-YYYY HH:mm");
  let prognose = moment(
    item.prognosewert.replace(".", "-"),
    "DD-MM-YYYY HH:mm"
  );

  let time_prognose = moment(prognose).format("HH:mm");
  let diff = moment(prognose).diff(today, "days");
  let letzterwertText = undefined;

  //currentEinheitText = item.einheittext;
  //tomorrow = moment(tomorrow, "DD-MM-YYYY HH:mm");

  if (item.letzterwert !== undefined) {
    let letzterWert = moment(
      item.letzterwert.replace(".", "-"),
      "DD-MM-YYYY HH:mm"
    );
    let time_letzterwert = moment(letzterWert).format("HH:mm");
    if (moment(letzterWert).isSame(today, "day")) {
      letzterwertText = "Heute " + time_letzterwert;
    } else {
      letzterwertText = moment(letzterWert).format("DD.MM.YYYY HH:mm");
    }
  }
  //Prognose konvertieren
  switch (item.prognosestatus) {
    /*case "0":
      currentPrognose = "";
      currentPrognoseWert = "";
      prognoseHeading = "";
      break;*/
    case "0":
    case "1":
    case "2":
      if (moment(prognose).isSame(today, "day")) {
        if (moment(today, "HH:mm") < moment(time_prognose, "HH:mm")) {
          currentPrognose = "Heute " + time_prognose + " Uhr";
          currentPrognoseWert =
            (item.wertmax - item.prognosebasis).toLocaleString(undefined, {
              maximumFractionDigits: 2,
            }) +
            " " +
            item.einheittext;
        } else {
          currentPrognose = "Jetzt ";
          currentPrognoseWert =
            (item.wertmax - item.prognosebasis).toLocaleString(undefined, {
              maximumFractionDigits: 2,
            }) +
            " " +
            item.einheittext;
        }
      } else {
        if (
          moment(prognose, "DD-MM-YYYY HH:mm") <
          moment(tomorrow, "DD-MM-YYYY HH:mm")
        ) {
          currentPrognose = "Morgen " + time_prognose + " Uhr";
          currentPrognoseWert =
            (item.wertmax - item.prognosebasis).toLocaleString(undefined, {
              maximumFractionDigits: 2,
            }) +
            " " +
            item.einheittext;
        } else if (diff >= 2 && currentPrognose !== "Morgen") {
          currentPrognose = "mehr als zwei Tage";
        } else {
          currentPrognose = "Morgen " + time_prognose + " Uhr";
          currentPrognoseWert =
            (item.wertmax - item.prognosebasis).toLocaleString(undefined, {
              maximumFractionDigits: 2,
            }) +
            " " +
            item.einheittext;
        }
      }
      break;
    default:
      currentPrognose = "";
      currentPrognoseWert = "";
      prognoseHeading = "";
  }

  function CheckMedianKZ(kz) {
    for (let x = 0; x < kz.length; x++) {
      switch (x) {
        case 0: //Median als Linie
          if (MedianToBool(kz[x])) showMedian = true;
          break;
        case 1: //Median als Fläche
          if (MedianToBool(kz[x])) {
            currentArea = "wertmedian";
            currentColorArea = colorAreaMedian;
          }
          break;
        case 3: //Median als akt. Wert
          if (MedianToBool(kz[x])) currentArea = "wertmedian";
          break;
        case 4: //aktuellen Wert als Linie
          if (MedianToBool(kz[x])) {
            currentLinie = "Wert";
            currentColorLinie = colorLinieWert;
          }
          showMedian = true;
          break;
        default:
          break;
      }
    }
  }

  function check_status(i) {
    switch (parseInt(i)) {
      case 0:
        return check;
      case 1:
        return warning;
      case 2:
        return cross;
      default:
        return undefined;
    }
  }

  function customizeTooltip(arg) {
    return {
      text:
        arg.value.toLocaleString(undefined, {
          maximumFractionDigits: 2,
        }) +
        " " +
        item.einheittext +
        "<br/>" +
        moment(arg.argument, "DD.MM.YYYY HH:mm").format("HH:mm") +
        " Uhr",
    };
  }

  return (
    <FadeIn>
      <div className="dtzMesspunktItemContent">
        <div className="dtzMesspunktItemHeader">
          <img className="dtzMesspunktItem" alt="status" src={statusSrc} />
          <span className="dtzMesspunktItemHeading">
            {item.standorttext} - {item.name} - {" "}
            {item.inhalttext ? item.inhalttext : "k.A."}
          </span>
        </div>
        <br></br>
        <div className="dtzMesspunktStripe">
          <span className="dtzMesspunktItemFuellstandHeader">Füllstand</span>
          <span className="dtzMesspunktItemFuellstandValue">
            {wert > 0 ? wert : 0} {item.einheittext}
          </span>
        </div>
        <div className="dtzMesspunktStripe">
          <span className="dtzMesspunktItemFuellstandHeader">Maximum</span>
          <span className="dtzMesspunktItemFuellstandValue">
            {wertmax} {item.einheittext}
          </span>
        </div>
        <div className="dtzMesspunktStripe">
          <span className="dtzMesspunktItemFuellstandHeader">Einfüllbar</span>
          <span className="dtzMesspunktItemFuellstandValue">
            {(item.wertmax - item.wert).toLocaleString(undefined, {
              maximumFractionDigits: 2,
            }) < 0
              ? 0
              : (item.wertmax - item.wert).toLocaleString(undefined, {
                  maximumFractionDigits: 2,
                })}{" "}
            {item.einheittext}
          </span>
        </div>
        <div className="dtzMesspunktStripePrognose">
          <span className="dtzMesspunktItemFuellstandHeader">
            {prognoseHeading}
          </span>
          <span className="dtzMesspunktItemFuellstandValue">
            {currentPrognose} {currentPrognoseWert}
          </span>
        </div>
        <CircularGauge
          value={(item.wert / item.wertmax) * 100}
          className="dtzMesspunktItemGauge"
        >
          <Tooltip enabled={true} customizeTooltip={gaugeText}></Tooltip>
          <ValueIndicator
            type="rectangleNeedle"
            beginAdaptingAtRadius={200}
            arrowLength={1}
            color="rgba(55, 55, 55, 0.5)"
          ></ValueIndicator>
          <Scale startValue={0} endValue={100} tickInterval={10}>
            <Label useRangeColors={true} visible={false} />
          </Scale>
          <RangeContainer palette="pastel">
            <Range
              startValue={0}
              endValue={(item.alarmwertgrenze / item.wert) * 100}
              color="#ffa500"
            />
            <Range
              startValue={(item.alarmwertgrenze / item.wert) * 100}
              endValue={100}
              color="#21BD28"
            />
          </RangeContainer>
          <Geometry startAngle={180} endAngle={0}></Geometry>
          <Export enabled={false} />
        </CircularGauge>
        <Chart className="dtzMesspunktChart" dataSource={item.historie}>
          <Title visible={false}></Title>
          <Series
            argumentField="Zeit"
            valueField={currentArea}
            type="area"
            color={currentColorArea}
          />
          <Series
            argumentField="Zeit"
            valueField={currentLinie}
            type="spline"
            width={3}
            color={currentColorLinie}
            visible={showMedian}
          />
          <Tooltip
            enabled={true}
            location="edge"
            customizeTooltip={customizeTooltip}
          />
          <CommonAxisSettings>
            <Grid visible={false}></Grid>
            <Tick visible={false}></Tick>
          </CommonAxisSettings>
          <CommonSeriesSettings opacity={0.8}>
            <Point visible={false} />
          </CommonSeriesSettings>
          <ValueAxis
            valueMarginsEnabled={false}
            visualRange={[0, item.wertmax]}
          >
            <Label visible={false}></Label>
          </ValueAxis>
          <ArgumentAxis valueMarginsEnabled={false} tickInterval={10}>
            <Label visible={false}></Label>
          </ArgumentAxis>
          <Legend visible={false}></Legend>
        </Chart>
        <div className="dtzMesspunktStripeZeit">
          <span className="dtzMesspunktItemFuellstandHeaderZeit">Zeit</span>
          <span className="dtzMesspunktItemFuellstandValue">
            {letzterwertText}
          </span>
        </div>
      </div>
    </FadeIn>
  );
}
